.productIconImageContainer {
  width: 100%;
  height: 15rem;
}
.plantIconImage {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.textClass {
  word-wrap: break-word;
}

.featButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Message {
  color: black;
  padding-right: 10px !important;
  font-family: inherit;
}
.waterButton {
  border-left: 2px solid rgb(226, 226, 226);
  color: #b8e8ee;
}

.growButton {
  border-left: 2px solid rgb(226, 226, 226);
  color: #69ec86;
}

.lightButton {
  color: #fced1d;
  padding-right: 3px;
}

@media screen and (max-width: 1113px) {
  .Message {
    font-size: smaller;
  }
}

@media screen and (min-width: 600px) and (max-width: 696px) {
  .Message {
    font-size: x-small;
  }
}

@media screen and (max-width: 599px) {
  .Message {
    font-size: small;
  }
}

@media (min-width: 900px) {
  .css-iltpxr-MuiGrid-root {
    max-width: 100% !important;
  }
}

@media (min-width: 600px) {
  .css-iltpxr-MuiGrid-root {
    max-width: 100% !important;
  }
}
.fixed-card-size {
  width: 300px; /* Set the desired fixed height for the card */
  height: 100%;
  overflow: hidden; /* Hide content overflow */
}