.searchBox{
    display: flex;
    justify-content: flex-end;
}
.home{
    display: flex;
    vertical-align: middle;
    align-items: center;
}
.MuiFormHelperText-root {
    color : red !important;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: aquamarine;
}
.scrollHide{
    overflow: hidden;
}