.image-media {
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.NavOver {
  margin-top: -8px !important;
}

.css-1bi20rk-MuiTypography-root {
  font-weight: 800 !important;
}
