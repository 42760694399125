.centerElement{
    display: flex;
    align-items: center;
    justify-content: center;
}
.css-1hvfsrb-MuiButtonBase-root-MuiButton-root{
    margin-left: 0% !important;
}
.css-be7s7w-MuiButtonBase-root-MuiButton-root{
    margin-left: 0% !important;
}
.Buttons{
    margin-left: 0% !important;
    align-items: center !important;
    justify-content: center !important;
}
.divSignUp{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

