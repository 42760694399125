.productList{
    width: 500px;
    height: 440px;
    flex: 4;
}

.MuiDataGrid-colCellTitle {
    display: block;
    text-align:center;
    width: 100%;
  }

.DataTable{
    justify-content: center;
}

.productListItem{
    display: flex;
    align-items: center;
    justify-items: center !important;
    justify-content: center !important;
    text-align: center;
}

.productListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.productListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.productListDelete{
    color: red;
    cursor: pointer;
}

.css-1ttfkg0-MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer{
    justify-content: center!important;
}
.css-1ttfkg0-MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer{
    justify-content: center!important;
}
.userAddButton {
    margin-left: 10px !important;
    margin-bottom: 10px !important;
    background-color: teal !important;
    color: white !important;
    font-size: 16px;
  }
