@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Poor+Story&display=swap');
@font-face {
  font-family: 'ComicNeue';
  src: local('ComicNeue'),
       url('./assets/Fonts/ComicNeue-Regular.ttf') format('opentype');
}


html {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}


::-webkit-scrollbar {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0px;
  height: 150px;
  background-color: transparent;
  background-image: -webkit-linear-gradient(
    270deg,
    #009688 5%,
    #26a69a 25%,
    transparent 100%,
    #00796b 75%,
    transparent
  );
}
::-webkit-scrollbar-thumb:hover {
  background-image: -webkit-linear-gradient(
    270deg,
    #024942 5%,
    #0a6d63 25%,
    transparent 100%,
    #06332e 75%,
    transparent
  );
}
