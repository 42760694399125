
.centerElement{
    display: flex;
    align-items: center;
    justify-content: center;
}
.divSignUp{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}
.signin-main{
    overflow: hidden !important;
}