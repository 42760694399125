@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Poor+Story&display=swap');

.body {
  /* background-color: whitesmoke; */
  overflow-x: hidden;
  font-family: Comic Neue !important;
}

.MuiTypography-root {
  font-family: Comic Neue !important;
}

.MuiTypography-body1 {
  font-family: Comic Neue !important;
}

#root {
  /* background-color: whitesmoke; */
  min-height: 100vh;
}

.App {
  /* background-color: whitesmoke; */
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}
