.productIconImageContainer1 {
  width: 100%;
  height: 15rem;
  z-index: 0;
}
.productIconImage1 {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ToolProductIconImage1 {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.productContainerImage {
  width: 100%;
  height: 100%;
}
