@keyframes fade-in {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade {
  animation: fade-in 500ms ease-in-out;
}
