// _library.scss
$primary: #079c45 !default;
$secondary: #b1a32a !default;

.text_underline_true{
    text-decoration: underline !important;
}
.text_main{
    color: $secondary;
    &:hover{
        color: $primary !important;
    }
    
} 
