@font-face {
    font-family: 'Pecita';
    src: local('Pecita'),
         url('../../assets/Fonts/Pecita.otf') format('opentype');
}

.logo-typography {
  font-family: Pecita !important;
}

.bg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg, #00c85390 0%, #ffeb3b30 25%, #00c85380 51%, #ffffff90 100%) !important;
  -webkit-animation: AnimateBG 20s ease infinite !important;
          animation: AnimateBG 20s ease infinite !important;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
