$red: #e84c3d;
$green: #2bc288;
$grey: #ecedef;
$black: #7a7a7a;

.cardWrap {
  width: 27em;
  margin: 3em auto;
  color: #fff;
  font-family: sans-serif;
  display: flex;
  align-items: stretch;
  -webkit-backface-visibility: hidden;
}

.card {
  // background: linear-gradient(to bottom, $red 0%, $red 26%, $grey 26%, $grey 100%);
  float: left;
  position: relative;
  padding: 1em;
}

.cardLeft {
  transform-origin:0 100%;
  transition:transform .25s linear;
  border-right: .09em dashed #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  width: 16em;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: .9em;

    background: #ffffff;
    border-radius: 50%;
    right: -.5em;
    //top:-.4em;
  }
  &:before {
    top: -.4em;
  }
  &:after {
  bottom: -.4em;
  }
}

.cardRight {
  transform-origin:300% 100%;
  transition:transform .25s linear;
  width: 6.5em;
  border-left: .09em dashed #fff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  &:before,
  &:after {
    content: "";
    position: absolute;
    display: block;
    width: .9em;
    background: #ffffff;
    border-radius: 50%;
    left: -.5em;
  }
  &:before {
    top: -.4em;
  }
  &:after {
  bottom: -.4em;
  }
}

h1 {
  font-size: 1.1em;
  margin-top: 0;
  span {
    font-weight: normal;
  }
}

.title {
  font-weight: normal;
  span {
    font-size: .7em;
    color: #a2aeae;
  }
  h2 {
    font-size: .6em;
    color: #929292;
  }
}

.name, .seat, .time {
  text-transform: uppercase;
  font-weight: normal;
  h2 {
    font-size: .9em;
    color: #929292;
    margin: 0;
   }
  span {
    font-size: .7em;
    color: #a2aeae;
  }
}

.title {
  margin: 2em 0 0 0;
}

.name, .seat {
  margin: .7em 0 0 0;
}

.time {
  margin: .7em 0 0 1em;
}

.seat, .time {
  float: left;
}

.eye {
  position: absolute;
  margin: 0 auto;
  border-radius: 1em/0.6em;
  z-index: 1;
  transform: translate(-50%, 0%);
  top: 10px;
  left: 50%;
}

.number {
  text-align: center;
  text-transform: uppercase;
  h3 {
    color: $green;
    margin: .9em 0 0 0;
    font-size: 2.5em;
    
  }
  span {
    display: block;
    color: #a2aeae;
  }
}

.barcode {
  height: 2em;
  width: 0;
  margin: 1.2em 0 0 .8em;
  box-shadow: 1px 0 0 1px $black,
  5px 0 0 1px $black,
  10px 0 0 1px $black,
  11px 0 0 1px $black,
  15px 0 0 1px $black,
  18px 0 0 1px $black,
  22px 0 0 1px $black,
  23px 0 0 1px $black,
  26px 0 0 1px $black,
  30px 0 0 1px $black,
  35px 0 0 1px $black,
  37px 0 0 1px $black,
  41px 0 0 1px $black,
  44px 0 0 1px $black,
  47px 0 0 1px $black,

}

.cardWrap:hover {
  .cardLeft{
    
    transform:translateX(-5px);
  }
  .cardRight{
    transform:translateX(15px);
  }
}